module.exports = {
  const: (FB = require("fb")),
  // Post Publication On Facebook
  async postPublicationOnFacebook(
    cardTexte,
    images,
    longUrl,
    TOKEN_FACEBOOK,
    Hashtags,
    FluxDescription,
    idFacebook
  ) {
    var shortLink = await this.getShortUrl(longUrl);

    FB.setAccessToken(TOKEN_FACEBOOK);
    FB.api(
      idFacebook + "/photos",

      "post",
      {
        message:
          cardTexte +
          "\r\n\r\n" +
          FluxDescription +
          "\r\n\r\n" +
          /*
          shortLink + 
          "\r\n\r\n" + */ //Lien Flux
          "#MercaNews #Mercarue \r\n" +
          Hashtags,
        url: images[0],
      },
      async function (res) {
        if (!res || res.error) {
          console.log(!res ? "error occurred" : res.error);
          return;
        }
      }
    );
  },
  // creat shot link
  async getShortUrl(longUrl) {
    const request = require("request");

    return new Promise((resolve) => {
      request.post(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDQxV3xrCmyRUxo5tQx2MPciKUhehnQj5M",
        {
          json: {
            longDynamicLink: "https://mercanews.page.link/?link=" + longUrl,
          },
        },
        async function (error, response, body) {
          if (error) {
            console.log(error);
          } else {
            resolve(body.shortLink);
          }
        }
      );
    });
  },

  //   Post Publication On Facebook
  async postPublicationOnInstagram(
    cardTexte,
    images,
    TOKEN_FACEBOOK,
    Hashtags,
    FluxDescription,
    idInstagram
  ) {
    const mediaObjectContainerId = await this.createMediaObjectContainer(
      cardTexte,
      images,
      TOKEN_FACEBOOK,
      Hashtags,
      FluxDescription,
      idInstagram
    );

    await this.publishMediaObjectContainer(
      mediaObjectContainerId,
      TOKEN_FACEBOOK,
      idInstagram, 
      Hashtags,
      FluxDescription,
      
    );
  },
  //   Prepare To Publish it in Instagram (!order des parameters)
  publishMediaObjectContainer(
    mediaObjectContainerId,
    TOKEN_FACEBOOK,
    idInstagram 
  ) {
    FB.setAccessToken(TOKEN_FACEBOOK);
    return new Promise((resolve) => {
      FB.api(
        idInstagram + "/media_publish",
        "POST",
        {
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  },

  async createMediaObjectContainer(
    cardTexte,
    images,
    TOKEN_FACEBOOK,
    Hashtags,
    FluxDescription,
    idInstagram
  ) {
    FB.setAccessToken(TOKEN_FACEBOOK);
    return new Promise((resolve) => {
      FB.api(
        idInstagram + "/media",
        "POST",
        {
          image_url: images[0],
          caption:
            cardTexte +
            "\r\n\r\n" +
            FluxDescription +
            "\r\n\r\n" +
            "#MercaNews #Mercarue \r\n" +
            Hashtags,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  },
};

<template>
  <div id="FluxList">
    <v-container>
      <dashboard-core-app-bar />
    </v-container>

    <v-row style="margin-top: 20px">
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <v-col cols="12" md="12">
        <v-card class="pa-3" outlined tile>
          <v-card-text>
            <v-col>
              <v-row>
                <v-col
                  style="margin-top: 15px"
                  cols="12"
                  sm="6"
                  xs="12"
                  md="6"
                  lg="4"
                  xl="3"
                >
                  <v-autocomplete
                    :items="fluxCategories"
                    item-value="category"
                    item-text="category"
                    label="Catégories"
                    chips
                    clearable
                    hide-details
                    hide-selected
                    v-model="category"
                    @input="onCategoryChange(category)"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  style="margin-top: 15px"
                  cols="12"
                  sm="6"
                  xs="12"
                  md="6"
                  lg="4"
                  xl="3"
                >
                  <v-autocomplete
                    :items="fluxSources"
                    item-value="sourceName"
                    item-text="sourceName"
                    label="Sources"
                    chips
                    clearable
                    hide-details
                    hide-selected
                    v-model="sourceName"
                    @input="onSourceChange(sourceName)"
                  ></v-autocomplete
                  >.
                </v-col>

                <v-col
                  style="margin-top: 15px"
                  cols="12"
                  sm="6"
                  xs="12"
                  md="6"
                  lg="4"
                  xl="3"
                >
                  <v-autocomplete
                    :items="LangueFlux"
                    chips
                    clearable
                    hide-details
                    hide-selected
                    label="Langue"
                    @input="onLangueChange(langue)"
                    v-model="langue"
                    item-value="langue"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  xs="12"
                  md="6"
                  lg="4"
                  xl="3"
                  style="margin-top: 25px"
                >
                  <hotel-date-picker
                    style="opacity: 90%; z-index: 2"
                    :halfDay="true"
                    :displayClearButton="true"
                    :i18n="frFR"
                    :positionRight="true"
                    :minNights="1"
                    :format="dateFormat"
                    :showYear="true"
                    :showCloseButton="false"
                    :startDate="new Date(new Date().getFullYear(), 0, 1)"
                    :endDate="new Date(Date.now() + 3600 * 1000 * 24)"
                    @check-in-changed="checkInChanged($event)"
                    @check-out-changed="checkOutChanged($event)"
                    @clear-selection="clear($event)"
                  />
                </v-col>

                <v-col cols="12" sm="6" xs="12" md="6" lg="4" xl="3">
                  <v-radio-group mandatory>
                    <v-radio
                      label="Tous les flux"
                      @change="onFiltreFlux('all')"
                    ></v-radio>
                    <v-radio
                      label="Flux selectionné"
                      @change="onFiltreFlux('selected')"
                    ></v-radio>
                    <v-radio
                      label="Flux publiés"
                      @change="onFiltreFlux('publish')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="card in cards"
                :key="card.title"
                cols="12"
                sm="6"
                xs="12"
                md="6"
                lg="4"
                xl="3"
              >
                <v-card>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="card.image_source"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: large"
                        v-text="card.category"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="card.sourceName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-spacer></v-spacer>

                    <v-icon v-if="card.publish" large color="#19b4bf">
                      mdi-check-all
                    </v-icon>
                    <v-icon
                      v-if="admin"
                      @click="selectflux(card.id, card.selected)"
                      @click.stop="card.selected = !card.selected"
                      large
                      color=" amber lighten-2 "
                    >
                      {{ !card.selected ? "mdi-star-outline" : "mdi-star" }}
                    </v-icon>
                  </v-list-item>
                  <v-img
                    :src="card.src"
                    v-on:error="card.src = MercanewsLogo"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <a :href="card.src" target="_blank">
                        <v-icon class="mr-4" large color="#19b4bf" download>
                          mdi-cloud-download
                        </v-icon>
                      </a>
                      <!-- envoyer notification  -->
                      <a
                        v-if="!card.notifieted"
                        @click="card.Notification = true"
                      >
                        <v-icon class="mr-2" large color="#19b4bf">
                          mdi-send
                        </v-icon>
                      </a>
                      <v-dialog
                        class="mx-auto"
                        max-width="500"
                        v-model="card.Notification"
                      >
                        <v-card>
                          <v-card-title class="headline" primary-title>
                            <h4 style="color: #19b4bf">Confirmation</h4>
                          </v-card-title>
                          <v-card-text>
                            <div class="ml-6">
                              Êtes-vous sûr d'envoyer cet article en tant que
                              notification?
                            </div>
                            <v-divider></v-divider>
                            <v-spacer></v-spacer>
                            <v-card class="mx-auto mt-5" max-width="400">
                              <v-img
                                class="white--text align-end"
                                height="200px"
                                :src="card.src"
                              >
                              </v-img>

                              <v-card-text>
                                <div>
                                  <span class="primary--text">{{
                                    card.text
                                  }}</span>
                                </div>

                                <div>
                                  <span>{{ card.description }}</span>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red lighten-2"
                              text
                              @click="card.Notification = false"
                            >
                              Non
                            </v-btn>
                            <v-btn
                              color="#19b4bf"
                              text
                              v-on:click.prevent="sendNotification(card)"
                              @click="card.Notification = false"
                            >
                              Oui
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-card-actions>
                  </v-img>
                  <!-- fin envoye notification  -->
                  <v-card-actions style="height: 70px">
                    <v-card-title style="font-size: 15px" v-text="card.text">
                    </v-card-title>
                  </v-card-actions>
                  <v-card-actions>
                    <a
                      class="ml-4"
                      :href="card.link"
                      target="_blank"
                      text
                      color="#19b4bf"
                    >
                      Consulter
                    </a>
                    <a
                      class="ml-4"
                      v-if="card.publish"
                      target="_blank"
                      @click="card.dialog = true"
                      text
                      color="#19b4bf"
                    >
                      Visualiser la publication
                    </a>
                    <v-dialog
                      class="mx-auto"
                      max-width="1000"
                      v-model="card.dialog"
                    >
                      <v-card> <v-img></v-img> </v-card>:src="card.image_publie"
                    </v-dialog>
                    <a
                      class="ml-4"
                      v-if="card.selected"
                      target="_blank"
                      text
                      color="#19b4bf"
                      @click="card.overlay = true"
                      @click.stop="exportCardDetails(card)"
                    >
                      Publier
                    </a>

                    <v-overlay
                      color="#fff"
                      :absolute="absolute"
                      :opacity="opacity"
                      v-if="card.overlay"
                    >
                      <vue-upload-multiple-image
                        class="ml-6"
                        name="images"
                        dragText="Drag image"
                        browseText="Browse image"
                        primaryText="Primary image"
                        markIsPrimaryText="Set default image"
                        popupText="Description default image"
                        dropText="Drag and drop"
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @edit-image="editImage"
                        @data-change="dataChange"
                        :data-images="image"
                        :maxImage="1"
                        :multiple="false"
                      ></vue-upload-multiple-image>

                      <v-btn
                        v-if="ImageUpload"
                        class="mt-6"
                        color="#19b4bf"
                        @click="confirmation = true"
                      >
                        Publier
                      </v-btn>
                      <v-dialog
                        class="mx-auto"
                        max-width="500"
                        v-model="confirmation"
                      >
                        <!-- CONFIRMATION  -->
                        <v-card>
                          <v-form ref="forme" v-model="valid">
                            <v-card-title class="headline" primary-title>
                              <h4 style="color: #19b4bf">Confirmation</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <v-card-text>
                              <div style="text-align: center">
                                <h5 style="text-align: center, color: #19b4bf">
                                  Veuillez ajouter une Description et des
                                  HashTags, Avant la confirmation de la
                                  publication
                                </h5>
                              </div>
                              <v-card class="mt-6">
                                <v-textarea
                                  counter="1600"
                                  auto-grow
                                  outlined
                                  rows="3"
                                  v-model="FluxDescription"
                                  :rules="rulesDescription"
                                  hint="Description Limit 1600 Characters"
                                  label="Description"
                                  shaped
                                  required
                                ></v-textarea>
                              </v-card>
                              <v-divider></v-divider>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-card class="mt-6">
                                <v-textarea
                                  counter="250"
                                  auto-grow
                                  outlined
                                  rows="3"
                                  v-model="Hashtags"
                                  :rules="rules"
                                  hint="HashTag Limit 250 Characters"
                                  label="HashTag"
                                  shaped
                                  required
                                ></v-textarea>
                              </v-card>
                              <v-card class="mx-auto mt-5" max-width="400">
                                <v-img
                                  class="white--text align-end"
                                  :src="images[0]"
                                >
                                </v-img>
                              </v-card>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red lighten-2"
                                text
                                @click="cancelConfirmation()"
                              >
                                Non
                              </v-btn>
                              <v-btn
                                color="#19b4bf"
                                text
                                :disabled="!valid"
                                v-on:click.prevent="
                                  publier(
                                    card.id,
                                    card.text,
                                    card.link,
                                    card.description
                                  )
                                "
                                @click="confirmation = false"
                                @click.stop="card.overlay = !card.overlay"
                                @click.prevent="card.publish = !card.publish"
                              >
                                Oui
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <!-- CONFIRMATION  -->
                      <v-btn
                        class="ml-3 mt-6"
                        color="#19b4bf"
                        @click.stop="this.images = []"
                        @click="card.overlay = !card.overlay"
                      >
                        Annuler
                      </v-btn>
                    </v-overlay>
                    <v-spacer></v-spacer>

                    <v-icon
                      large
                      class="mr-2"
                      @click="card.showDescription = !card.showDescription"
                      color="#19b4bf"
                      >{{
                        !card.showDescription
                          ? "mdi-arrow-down"
                          : "mdi-arrow-up"
                      }}</v-icon
                    >
                  </v-card-actions>

                  <v-expand-transition>
                    <div v-show="card.showDescription">
                      <v-divider></v-divider>
                      <v-card-text v-text="card.text"> </v-card-text>
                      <v-card-text v-text="card.description"> </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-btn
                @click="onPageChange()"
                class="ma-2"
                outlined
                large
                fab
                color="#19b4bf"
              >
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import MercanewsLogo from "@/assets/Mercanews.png";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import FunctionsApi from "../../utils/functionAPI";
import NodesName from "../../utils/nodesName";
import NodesFieldName from "../../utils/nodesFieldName";
import mdb from "../../../mercanewsFirebaseInit";
import mstorage from "../../../mercanewsStorageInit";
import HotelDatePicker from "vue-hotel-datepicker2";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
import "firebase/firestore";
import FunctionsUtils from "../../utils/functions";
import FirebaseDB from "../../utils/firebaseDB";
import firebase from "firebase";
import db from "../../../firebaseInit";

export default {
  name: "FluxListe",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    HotelDatePicker,
    VueUploadMultipleImage,
  },
  data() {
    return {
      admin: false,
      token: null,
      idFacebook: null,
      idInstagram: null,
      publierSurFacebook: false,
      publierSurInstagram: false,
      MercanewsLogo: MercanewsLogo,
      sourceImageMap: new Map(),
      sourceIDMap: new Map(),
      articleCategory: null,
      articleSource: null,
      articleId: null,
      images: [],
      image: [],
      ImageUpload: false,
      absolute: true,
      opacity: 1,
      lastVisibleFlux: null,
      total: 0,
      pagination: 0,
      perPage: 10,
      show: false,
      page: 1,
      cards: [],
      fluxCategories: [],
      fluxSources: [],
      sourceImage: [],
      category: null,
      sourceName: null,
      source: null,
      id: null,
      frFR: {
        night: "jour",
        nights: "jours",
        "check-in": "",
        "check-out": "Date",
        "day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        tooltip: {
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
        },
      },
      fromDate: new Date("2021-01-01"),
      toDate: new Date(Date.now() + 3600 * 1000 * 24),
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      langue: null,
      LangueFlux: [
        {
          text: "Arabe",
        },
        {
          text: "Français",
        },
      ],
      dates: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      notifieted: false,
      dialog: false,
      dialogNotification: false,
      confirmation: false,
      MercanewsLogo: null,
      Hashtags: null,
      FluxDescription: null,
      rules: [
        (v) => !!v || "HashTag requis. ",
        (v) =>
          (v && v.length >= 20) ||
          "HashTag doit contenir au moins 20 caractères.",
        (v) =>
          (v && v.length < 250) ||
          "HashTag ne doit pas dépasser 250 caractères.",
      ],
      rulesDescription: [
        (v) => !!v || "Description requis. ",
        (v) =>
          (v && v.length >= 20) ||
          "Description doit contenir au moins 20 caractères.",
        (v) =>
          (v && v.length < 1600) ||
          "Description ne doit pas dépasser  1600 caractères.",
      ],
      valid: false,
    };
  },

  methods: {
    // Annuler Confirmation
    cancelConfirmation() {
      this.confirmation = false;
      this.Hashtags = null;
      this.FluxDescription = null;
    },
    //  Envoyer Notification
    sendNotification(card) {
      mdb
        .collection(NodesName.RSS_FEED)
        .doc(card.id)
        .update({
          notifieted: true,
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
      mdb
        .collection(NodesName.NOTIFICATIONS)
        .doc()
        .set({
          title: card.text,
          body: card.description,
          image: card.src,
        })
        .then(() => {
          card.notifieted = true;
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    },
    async uploadImageSuccess(formData, index, fileList) {
      var imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf("."));
      const file = FunctionsUtils.convertImagePathToFile(fileList[index]);

      await mstorage
        .ref(
          "images_a_publier/" +
            this.articleCategory +
            "/" +
            this.articleSource +
            "/" +
            this.articleId +
            "/" +
            imageName
        )
        .put(file)
        .then((response) => {
          mstorage
            .ref(
              "images_a_publier/" +
                this.articleCategory +
                "/" +
                this.articleSource +
                "/" +
                this.articleId +
                "/" +
                imageName
            )
            .getDownloadURL()
            .then((url) => {
              this.images.push(url);
            })
            .catch(function (error) {
              // Handle any errors
            });
        });
      this.ImageUpload = true;
    },

    async publier(id, cardTexte, longUrl, description) {
      if (this.publierSurFacebook == true) {
        FunctionsApi.postPublicationOnFacebook(
          cardTexte,
          this.images,
          longUrl,
          this.token,
          this.Hashtags,
          this.FluxDescription,
          this.idFacebook
        );
      }

      if (this.publierSurInstagram == true) {
        FunctionsApi.postPublicationOnInstagram(
          cardTexte,
          this.images,
          this.token,
          this.Hashtags,
          this.FluxDescription,
          this.idInstagram
        );
      }

      if (this.publierSurInstagram == true || this.publierSurFacebook == true) {
        mdb
          .collection(NodesName.RSS_FEED)
          .doc(id)
          .update({
            image_publie: this.images[0],
            publish: true,
            selected: false,
          })
          .catch(function (error) {
            console.error("Error updating document: ", error);
          });
      }

      this.ImageUpload = false;
      this.images = [];
      this.confirmation = false;
      this.Hashtags = null;
      this.FluxDescription = null;
    },

    beforeRemove(index, done, fileList) {
      //console.log('index', index, fileList)
      var r = confirm("remove image");
      if (r == true) {
        var indexInArrayImages = this.value.images.indexOf(
          fileList[index].path
        );
        if (index > -1) {
          this.value.images.splice(indexInArrayImages, 1);
        }
        this.images = [];
        done();
      }
    },
    editImage(formData, index, fileList) {
      //console.log('edit data', formData, index, fileList)
    },
    dataChange(data) {
      //console.log(data)
    },

    selectflux(id, selected) {
      if (this.admin) {
        mdb
          .collection(NodesName.RSS_FEED)
          .doc(id)
          .update({
            selected: !selected,
          })
          .catch(function (error) {
            console.error("Error updating document: ", error);
          });
      }
    },
    onCategoryChange: function (category) {
      this.category = category;
      this.lastVisibleFlux = null;
      this.cards = [];
      this.getfluxliste();
    },
    onSourceChange: function (source) {
      this.source = this.sourceIDMap.get(source);
      this.lastVisibleFlux = null;
      this.cards = [];
      this.getfluxliste();
    },
    onLangueChange: function (langue) {
      this.langue = langue;
      this.lastVisibleFlux = null;
      this.cards = [];
      this.getfluxliste();
    },
    clear() {
      this.fromDate = new Date("2021-01-01");
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.lastVisibleFlux = null;
      this.cards = [];
      this.getfluxliste();
    },
    checkInChanged(newDate) {
      this.fromDate = newDate;
      this.lastVisibleFlux = null;
      this.cards = [];
      this.getfluxliste();
    },
    checkOutChanged(newDate) {
      this.toDate = newDate;
      this.lastVisibleFlux = null;
      this.cards = [];
      this.getfluxliste();
    },
    onFiltreFlux: function (Flux) {
      this.Flux = Flux;
      this.cards = [];
      this.lastVisibleFlux = null;
      this.getfluxliste();
    },
    // Recuperation des Flux a l'ouverture de l'ecran
    async getfluxliste() {
      var self = this;
      var socialMedia = await FirebaseDB.GetSocialMediaInformations();
      this.token = socialMedia.token;
      this.idFacebook = socialMedia.idFacebook;
      this.idInstagram = socialMedia.idInstagram;
      this.publierSurFacebook = socialMedia.publieFacebook;
      this.publierSurInstagram = socialMedia.publieInstagram;

      await this.getSourcesWithImages();
      this.total = 200;
      this.pagination = Math.ceil(this.total / this.perPage);
      var query = mdb
        .collection(NodesName.RSS_FEED)
        .where(NodesFieldName.ISODATE, ">=", this.fromDate)
        .where(NodesFieldName.ISODATE, "<=", this.toDate);

      if (this.category) {
        query = query.where(NodesFieldName.LIBELLE, "==", this.category);
      }
      if (this.source) {
        query = query.where(NodesFieldName.SOURCE_NAME, "==", this.source);
      }
      if (this.langue == "Arabe") {
        query = query.where("language", "==", "AR");
      } else if (this.langue == "Français") {
        query = query.where("language", "==", "FR");
      }

      if (self.Flux === "selected") {
        query = query.where(NodesFieldName.SELECTED, "==", true);
      } else if (self.Flux === "publish") {
        query = query.where(NodesFieldName.PUBLISH, "==", true);
      } else {
        query = query;
      }
      query
        .limit(this.perPage)
        .orderBy(NodesFieldName.ISODATE, "desc")
        .get()
        .then(function (querySnapshot) {
          let i = 0;
          querySnapshot.forEach((flux) => {
            self.cards.push({
              id: flux.id,
              text: flux.data().title,
              src: flux.data().image,
              flex: 6,
              category: flux.data().libelle,
              description: flux.data().description,
              link: flux.data().link,
              sourceName: flux.data().sourceName,
              date: flux.data().date,
              showDescription: false,
              like: false,
              selected: flux.data().selected,
              overlay: false,
              publish: flux.data().publish,
              image_publie: flux.data().image_publie,
              image_source: self.sourceImageMap.get(flux.data().sourceName),
              dialog: false,
              notifieted: flux.data().notifieted,
              Notification: false,
            });

            i++;
            if (querySnapshot.size == i) {
              self.lastVisibleFlux = flux;
            }
          });
        });
    },
    // Recuperation des Flux on Clic sur le flesh bas
    onPageChange() {
      var self = this;
      var query = mdb
        .collection(NodesName.RSS_FEED)
        .where(NodesFieldName.ISODATE, ">=", this.fromDate)
        .where(NodesFieldName.ISODATE, "<=", this.toDate);

      if (this.category) {
        query = query.where(NodesFieldName.LIBELLE, "==", this.category);
      }
      if (this.source) {
        query = query.where(NodesFieldName.SOURCE_NAME, "==", this.source);
      }
      if (this.langue == "Arabe") {
        query = query.where("language", "==", "AR");
      } else if (this.langue == "Français") {
        query = query.where("language", "==", "FR");
      }
      if (self.Flux === "selected") {
        query = query.where(NodesFieldName.SELECTED, "==", true);
      } else if (self.Flux === "publish") {
        query = query.where(NodesFieldName.PUBLISH, "==", true);
      } else {
        query = query;
      }
      query
        .limit(this.perPage)
        .orderBy(NodesFieldName.ISODATE, "desc")
        .startAfter(self.lastVisibleFlux)
        .get()
        .then(function (querySnapshot) {
          let i = 0;
          querySnapshot.forEach((flux) => {
            self.cards.push({
              id: flux.id,
              text: flux.data().title,
              src: flux.data().image,
              flex: 6,
              category: flux.data().libelle,
              description: flux.data().description,
              link: flux.data().link,
              sourceName: flux.data().sourceName,
              date: flux.data().date,
              showDescription: false,
              like: false,
              selected: flux.data().selected,
              overlay: false,
              publish: flux.data().publish,
              image_publie: flux.data().image_publie,
              image_source: self.sourceImageMap.get(flux.data().sourceName),
              dialog: false,
              notifieted: flux.data().notifieted,
              Notification: false,
            });

            i++;
            if (querySnapshot.size == i) {
              self.lastVisibleFlux = flux;
            }
          });
        });
    },
    // Recuperation liste des noms des Categories pour filtrage
    getCategories() {
      mdb
        .collection(NodesName.CATEGORIES)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.fluxCategories.push({
              category: doc.data().category,
            });
          });
        });
    },
    // Recuperation liste des noms des Sources pour filtrage
    getSources() {
      mdb
        .collection(NodesName.SOURCE_FEED)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.fluxSources.push({
              sourceName: doc.data().sourceName,
              id: doc.data().uniqueId,
            });
          });
        });
    },
    // Creation Map pour Source Image
    getSourcesWithImages() {
      mdb
        .collection(NodesName.SOURCE_FEED)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.sourceImageMap.set(
              doc.data().uniqueId,
              doc.data().sourceImage
            );
          });
        });
    },
    // Creation Map pour Source id
    getSourcesID() {
      mdb
        .collection(NodesName.SOURCE_FEED)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.sourceIDMap.set(doc.data().sourceName, doc.data().uniqueId);
          });
        });
    },
    //Exportation des coordonneés du flux pour sauvgarder l'image dans Storage Firebase
    exportCardDetails(card) {
      this.articleCategory = card.category;
      this.articleSource = card.sourceName;
      this.articleId = card.id;
    },
  },
  created() {
    this.getfluxliste();
    this.getCategories();
    this.getSources();
    this.getSourcesID();

    db.collection(NodesName.GENERAL_SETTINGS)
      .doc(NodesName.LOGOMERCANEWS)
      .get()
      .then((DocumentSnapshot) => {
        this.MercanewsLogo = DocumentSnapshot.data().lien;
      });
  },
  beforeMount() {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          if (tokenResult.claims.editor) {
            this.admin = true;
          }
        });
    }
  },

  computed: {
    dateFormat() {
      return "MM/DD/YYYY";
    },
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    },
    headers() {},
  },
};
</script>
<style>
.highlights__content {
  position: relative;
}

.highlights__placeholder {
  color: rgb(141, 141, 141);
  position: absolute;
  top: 16px;
  left: 16px;
}

.highlights__body-container {
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 16px;
}

.highlights__body {
  min-height: 60px;
}

.highlights {
  color: #ff3b8e;
}
</style>
